import React, { useEffect, useState } from 'react';
import { AppBar, Avatar, Box, Button, IconButton, Popover, Toolbar, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../../types/user/interfaces';
import AuthService from "../../logic/AuthService";

interface NavBarProps {
    drawerWidth: number;
    isMobile: boolean;
    onDrawerOpen: () => void;
}

const TopNavigationBar: React.FC<NavBarProps> = ({ drawerWidth, isMobile, onDrawerOpen }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userData, setUserData] = useState<UserData | null>(null);

    useEffect(() => {
        const data = localStorage.getItem('userData');
        if (data) {
            const parsedData: UserData = JSON.parse(data);
            setUserData(parsedData);
        }
    }, []);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        const responseStatus = await AuthService.processLogOut();
        if (responseStatus) {
            handleProfileMenuClose();
            navigate('/');
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
                ml: isMobile ? 0 : `${drawerWidth}px`,
                backgroundColor: 'transparent',
            }}
            elevation={0}
        >
            <Toolbar>
                {isMobile && (
                    <IconButton
                        edge="start"
                        aria-label="menu"
                        onClick={onDrawerOpen}
                        sx={{ color: theme.palette.primary.main, display: { xs: 'block', sm: 'none' }, '&:hover': { backgroundColor: 'transparent' } }}
                        disableRipple
                        disableFocusRipple
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Box style={{ marginLeft: 'auto' }}>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        sx={{ color: theme.palette.primary.main, '&:hover': { backgroundColor: 'transparent' } }}
                        disableRipple
                        disableFocusRipple
                    >
                        <Avatar alt={userData?.username} />
                        <ArrowDropDownIcon />
                    </IconButton>
                    <Popover
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleProfileMenuClose}
                        sx={{ padding: '16px' }}
                    >
                        <Box display="flex" alignItems="center" sx={{ padding: '8px', backgroundColor: theme.palette.background.alternate }}>
                            <Button onClick={handleLogout} sx={{ padding: '8px', color: theme.palette.text.primary, display: 'flex', alignItems: 'center' }}>
                                <LogoutIcon sx={{ color: theme.palette.primary.main, marginRight: '8px' }} />
                                <Typography variant="body2" sx={{ color: theme.palette.primary.main, fontWeight: 700 }}>Выйти из аккаунта</Typography>
                            </Button>
                        </Box>
                    </Popover>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default TopNavigationBar;
