import axios from 'axios';
import { apiUrl } from '../App';
import {Category, Product, ProductRequestDTO} from "../types/products/interfaces";

class ProductAndCategoryService {

    async createProduct(product: ProductRequestDTO): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/product`, product);
            return response.status === 201;
        } catch (error) {
            console.error('Error while creating product:', error);
            return false;
        }
    }

    async deleteProduct(productId: string): Promise<boolean> {
        try {
            const response = await axios.delete(`${apiUrl}/product/${productId}`);
            return response.status === 204;
        } catch (error) {
            console.error('Error while deleting product:', error);
            return false;
        }
    }

    async updateProductMedia(
        productId: string,
        firstMedia: File | null, secondMedia: File | null, thirdMedia: File | null,
        fourthMedia: File | null, fifthMedia: File | null, sixthMedia: File | null,
        firstMediaKey: string | null, secondMediaKey: string | null, thirdMediaKey: string | null,
        fourthMediaKey: string | null, fifthMediaKey: string | null, sixthMediaKey: string | null
    ): Promise<void> {
        const formData = new FormData();

        formData.append('firstMedia', firstMedia ? firstMedia : new Blob(['null'], { type: 'text/plain' }), firstMedia ? firstMedia.name : 'null');
        formData.append('secondMedia', secondMedia ? secondMedia : new Blob(['null'], { type: 'text/plain' }), secondMedia ? secondMedia.name : 'null');
        formData.append('thirdMedia', thirdMedia ? thirdMedia : new Blob(['null'], { type: 'text/plain' }), thirdMedia ? thirdMedia.name : 'null');
        formData.append('fourthMedia', fourthMedia ? fourthMedia : new Blob(['null'], { type: 'text/plain' }), fourthMedia ? fourthMedia.name : 'null');
        formData.append('fifthMedia', fifthMedia ? fifthMedia : new Blob(['null'], { type: 'text/plain' }), fifthMedia ? fifthMedia.name : 'null');
        formData.append('sixthMedia', sixthMedia ? sixthMedia : new Blob(['null'], { type: 'text/plain' }), sixthMedia ? sixthMedia.name : 'null');

        formData.append('firstMediaKey', firstMediaKey ?? 'null');
        formData.append('secondMediaKey', secondMediaKey ?? 'null');
        formData.append('thirdMediaKey', thirdMediaKey ?? 'null');
        formData.append('fourthMediaKey', fourthMediaKey ?? 'null');
        formData.append('fifthMediaKey', fifthMediaKey ?? 'null');
        formData.append('sixthMediaKey', sixthMediaKey ?? 'null');

        try {
            await axios.post(`${apiUrl}/product/${productId}/update-media`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    async getAllProductsForAdmin(): Promise<Product[]> {
        try {
            const response = await axios.get(`${apiUrl}/product/admin`);
            return response.data;
        } catch (error) {
            console.error('Error while fetching products:', error);
            return [];
        }
    }

    async updateProduct(updatedProduct: Product, oldId: string): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/product/${oldId}/update`, updatedProduct);
            return response.status === 200;
        } catch (error) {
            console.error('Error while updating product:', error);
            return false;
        }
    }

    async createCategory(categoryName: string): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/product/category/create`, null, {
                params: { categoryName }
            });
            return response.status === 201;
        } catch (error) {
            console.error('Error while adding category: ', error);
            return false;
        }
    }

    async addParamInCategory(categoryName: string, parameterName: string): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/product/category/param`, null, {
                params: { categoryName, parameterName }
            });
            return response.status === 201;
        } catch (error) {
            console.error('Error while adding category parameter: ', error);
            return false;
        }
    }

    async deleteParamInCategory(categoryName: string, parameterName: string): Promise<boolean> {
        try {
            const response = await axios.post(`${apiUrl}/product/category/delete-param`, null, {
                params: { categoryName, parameterName }
            });
            return response.status === 200;
        } catch (error) {
            console.error('Error while deleting category parameter: ', error);
            return false;
        }
    }

    async getAllCategories(): Promise<Category[]> {
        try {
            const response = await axios.get(`${apiUrl}/product/category`);
            return response.data;
        } catch (error) {
            console.error('Error while fetching categories: ', error);
            return [];
        }
    }

    async addProductPosition(productId: string, size: string): Promise<boolean> {
        try {
            const response = await axios.put(`${apiUrl}/product/${productId}/${size}`);
            return response.status === 200;
        } catch (error) {
            console.error('Error while adding product position:', error);
            return false;
        }
    }

    async deleteProductPosition(productId: string, positionId: string): Promise<boolean> {
        try {
            const response = await axios.delete(`${apiUrl}/product/${productId}/${positionId}`);
            return response.status === 200;
        } catch (error) {
            console.error('Error while deleting product position:', error);
            return false;
        }
    }

}

export default new ProductAndCategoryService();