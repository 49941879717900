import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Box,
    IconButton,
    Typography, Tooltip
} from "@mui/material";
import {Add, Delete} from '@mui/icons-material';
import CategoryService from "../../../logic/ProductAndCategoryService";
import ProductAndCategoryService from "../../../logic/ProductAndCategoryService";
import {ProductRequestDTO} from "../../../types/products/interfaces";

interface AddGoodsDialogProps {
    open: boolean;
    onClose: () => void;
    onAddProduct: (good: any) => void;
}

interface SizeEntry {
    size: string;
    count: number;
}

const AddProductDialog: React.FC<AddGoodsDialogProps> = ({ open, onClose, onAddProduct }) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState<number | string>('');
    const [description, setDescription] = useState('');
    const [sizes, setSizes] = useState<SizeEntry[]>([]);
    const [categories, setCategories] = useState<{ [key: string]: string[] }>({});
    const [selectedCategories, setSelectedCategories] = useState<{ [key: string]: string }>({});
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryValue, setNewCategoryValue] = useState<{ [key: string]: string }>({});

    const fetchCategories = async () => {
        const fetchedCategories = await CategoryService.getAllCategories();
        const categoryMap: { [key: string]: string[] } = {};
        fetchedCategories.forEach(cat => {
            categoryMap[cat.name] = cat.values || [];  // <-- Здесь добавлена проверка
        });
        setCategories(categoryMap);
    };


    useEffect(() => {
        if (open) {
            fetchCategories();
        }
    }, [open]);

    const handleAddSize = () => {
        setSizes([...sizes, { size: '', count: 1 }]);
    };

    const handleRemoveSize = (index: number) => {
        setSizes(sizes.filter((_, i) => i !== index));
    };

    const handleDeleteValue = async (category: string, value: string) => {
        const success = await ProductAndCategoryService.deleteParamInCategory(category, value);
        if (success) {
            await fetchCategories();
        } else {
            alert('Ошибка при удалении параметра.');
        }
    };

    const handleSizeChange = (index: number, field: keyof SizeEntry, value: string | number) => {
        const newSizes = [...sizes];
        newSizes[index] = { ...newSizes[index], [field]: value };
        setSizes(newSizes);
    };

    const handleCategoryChange = (category: string, value: string) => {
        setSelectedCategories(prev => ({
            ...prev,
            [category]: value
        }));
    };

    const handleAddNewCategory = async () => {
        if (newCategoryName) {
            await CategoryService.createCategory(newCategoryName);
            setCategories(prev => ({
                ...prev,
                [newCategoryName]: []
            }));
            setNewCategoryName('');
        }
    };

    const handleAddNewValue = async (category: string) => {
        const value = newCategoryValue[category];
        if (value) {
            await CategoryService.addParamInCategory(category, value);
            setCategories(prev => ({
                ...prev,
                [category]: [...prev[category], value]
            }));
            setNewCategoryValue(prev => ({
                ...prev,
                [category]: ''
            }));
        }
    };

    const handleSubmit = async () => {
        const newProduct: ProductRequestDTO = {
            id,
            name,
            price: Number(price),
            description,
            categories: Object.keys(selectedCategories).map(category => ({
                name: category,
                value: selectedCategories[category]
            })),
            positions: sizes.map(size => ({
                size: size.size,
                count: size.count
            }))
        };

        const success = await ProductAndCategoryService.createProduct(newProduct);
        if (success) {
            onAddProduct(newProduct);
            onClose();
        } else {
            alert('Ошибка при создании продукта.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Добавить новый товар</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Название"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Артикул"
                    type="text"
                    fullWidth
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Цена"
                    type="number"
                    fullWidth
                    value={price}
                    onChange={(e) => setPrice(Math.max(0, Number(e.target.value)))}
                    error={Number(price) <= 0}
                    helperText={Number(price) <= 0 ? 'Цена должна быть больше нуля' : ''}
                />
                <TextField
                    margin="dense"
                    label="Описание"
                    type="text"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <Typography variant="h6" marginTop={2}>Категории и значения</Typography>
                {Object.keys(categories).map((category, idx) => (
                    <Box key={idx} marginBottom={2}>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>{category}</InputLabel>
                            <Select
                                value={selectedCategories[category] || ''}
                                onChange={(e) => handleCategoryChange(category, e.target.value as string)}
                                renderValue={(selected) => <span>{selected}</span>} // Отображаем только текст выбранного значения
                            >
                                {categories[category].map((value, index) => (
                                    <MenuItem key={index} value={value} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{value}</span>
                                        <Tooltip title="Удалить параметр">
                                            <IconButton
                                                size="small"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleDeleteValue(category, value);
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box display="flex" alignItems="center" marginTop={1}>
                            <TextField
                                margin="dense"
                                label={`Добавить новое значение в ${category}`}
                                type="text"
                                value={newCategoryValue[category] || ''}
                                onChange={(e) => setNewCategoryValue(prev => ({
                                    ...prev,
                                    [category]: e.target.value
                                }))}
                            />
                            <IconButton onClick={() => handleAddNewValue(category)}>
                                <Add />
                            </IconButton>
                        </Box>
                    </Box>
                ))}

                <Typography>Для добавления новой категории напишите её название в поле ниже</Typography>
                <Box display="flex" alignItems="center" marginBottom={2}>
                    <TextField
                        margin="dense"
                        label="Добавить новую категорию"
                        type="text"
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                    <IconButton onClick={handleAddNewCategory}>
                        <Add />
                    </IconButton>
                </Box>

                <Box marginTop={2}>
                    <Typography variant="h6">Размеры и количество</Typography>
                    {sizes.map((size, index) => (
                        <Box key={index} display="flex" alignItems="center" marginBottom={1}>
                            <TextField
                                margin="dense"
                                label="Размер"
                                type="text"
                                value={size.size}
                                onChange={(e) => handleSizeChange(index, 'size', e.target.value)}
                                style={{ marginRight: 8 }}
                            />
                            <TextField
                                margin="dense"
                                label="Количество"
                                type="number"
                                value={size.count}
                                onChange={(e) => handleSizeChange(index, 'count', Math.max(1, Number(e.target.value)))}
                                style={{ marginRight: 8 }}
                                error={Number(size.count) < 1}
                                helperText={Number(size.count) < 1 ? 'Количество должно быть не менее 1' : ''}
                            />
                            <Tooltip title="Удалить размер">
                                <IconButton onClick={() => handleRemoveSize(index)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    ))}
                    <Button variant="outlined" onClick={handleAddSize} startIcon={<Add />}>
                        Добавить размер
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Отмена
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProductDialog;
