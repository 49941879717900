import axios from "axios";
import {apiUrl} from "../App";

export interface InfoAboutDocuments {
    id: string;
    privacyPolicyUrl: string | null;
    termsOfServiceUrl: string | null;
    termsOfDeliveryUrl: string | null;
    userAgreementUrl: string | null;
    dataProcessingPolicyUrl: string | null;
    lastUpdated: string;
}

class DocumentsService {
    async getAllDocuments(): Promise<InfoAboutDocuments> {
        try {
            const response = await axios.get(`${apiUrl}/media/docs`);
            return response.data;
        } catch (error) {
            console.error('Error fetching documents', error);
            throw error;
        }
    }

    async updateDocument(fieldName: string, file: File): Promise<InfoAboutDocuments> {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fieldName', fieldName);

            const response = await axios.patch(`${apiUrl}/media/docs/update`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (error) {
            console.error('Error updating document', error);
            throw error;
        }
    }
}

export default new DocumentsService();