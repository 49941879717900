import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Link,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import {useNavigate} from "react-router-dom";
import {Search} from "@mui/icons-material";
import OrderService, {Order, OrderStatus, OrderStatusText} from "../../logic/OrderService";


const OrderComponent: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
    const [newAddress, setNewAddress] = useState('');
    const [newDate, setNewDate] = useState('');

    const [isReturn, setIsReturn] = useState(false);

    const [orders, setOrders] = useState<Order[]>([]);

    const [searchQuery, setSearchQuery] = useState('');

    const fetchOrders = async () => {
        try {
            const fetchedOrders = await OrderService.getAllOrders();
            setOrders(fetchedOrders);
        } catch (error) {
            console.error("Ошибка при загрузке заказов", error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);


    const filterOrdersBySearch = (orders: Order[], query: string): Order[] => {
        if (!query) return orders;

        const lowercasedQuery = query.toLowerCase();
        return orders.filter(order =>
            order.id.toLowerCase().includes(lowercasedQuery) ||
            order.contactEmail.toLowerCase().includes(lowercasedQuery) ||
            order.contactPhone.includes(lowercasedQuery) ||
            order.firstName.toLowerCase().includes(lowercasedQuery) ||
            order.secondName.toLowerCase().includes(lowercasedQuery) ||
            (order.patronymic && order.patronymic.toLowerCase().includes(lowercasedQuery)) ||
            order.items.some(item => item.productId.toLowerCase().includes(lowercasedQuery))
        );
    };

    const filterOrders = () => {
        const urgentOrders = orders.filter(order =>
            order.currentStatus === OrderStatus.VERIFYING && !order.isPassportInfoExists
        );

        const deliveryOrReturnRequiredOrders = orders.filter(order =>
            (order.currentStatus === OrderStatus.PREPARE && !order.dateOfDelivery) ||
            (order.currentStatus === OrderStatus.IN_RENT && !order.dateOfReturn) ||
            (order.currentStatus === OrderStatus.CANCELED_DURING_RENT && !order.dateOfReturn)
        );

        const urgentDeliveryOrReturnOrders = orders.filter(order =>
            (order.currentStatus === OrderStatus.DELIVERY_REGISTERED && order.dateOfDelivery) ||
            (order.currentStatus === OrderStatus.RETURN_REGISTERED && order.dateOfReturn) ||
            (order.currentStatus === OrderStatus.RETURN_REGISTERED_AFTER_CANCELLATION && order.dateOfReturn)
        ).sort((a, b) => {
            const dateA = dayjs(a.dateOfDelivery || a.dateOfReturn);
            const dateB = dayjs(b.dateOfDelivery || b.dateOfReturn);
            return dateA.isBefore(dateB) ? -1 : 1;
        });

        const allOrders = filterOrdersBySearch(orders, searchQuery);

        return { urgentOrders, deliveryOrReturnRequiredOrders, allOrders, urgentDeliveryOrReturnOrders };
    };

    const { urgentOrders, deliveryOrReturnRequiredOrders, allOrders, urgentDeliveryOrReturnOrders } = filterOrders();

    const handleClickOpen = (order: Order) => {
        setSelectedOrder(order);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setSelectedOrder(null);
    };

    const handleAddressDialogOpen = (isReturn: boolean) => {
        setIsReturn(isReturn);
        setIsAddressDialogOpen(true);
    };

    const handleSaveChanges = async () => {
        try {
            if (selectedOrder) {
                let currentAddress = isReturn ? selectedOrder.returnAddress : selectedOrder.deliveryAddress;
                let params: Record<string, string> = {
                    deliveryDate: newDate,
                };

                if (newAddress !== currentAddress) {
                    params.newDeliveryAddress = newAddress;
                }

                if (isReturn) {
                    await OrderService.changeReturnDateAndReturnAddress(selectedOrder.id, newDate, params.newDeliveryAddress);
                } else {
                    await OrderService.changeDeliveryDateAndAddress(selectedOrder.id, newDate, params.newDeliveryAddress);
                }

                console.log("Данные успешно обновлены");
                setIsAddressDialogOpen(false);
            } else {
                console.log("Данные не были обновлены, не найден заказ для обновления");
            }
        } catch (error) {
            console.error("Ошибка при обновлении данных доставки/возврата", error);
        }
        setIsAddressDialogOpen(false);
        window.location.reload();
    };


    const handleAddressDialogClose = () => {
        setIsAddressDialogOpen(false);
    };

    const handleConfirmDelivery = async (orderId: string, success: boolean) => {
        try {
            await OrderService.updateOrderStatusToDelivered(orderId, success);
            console.log(`Доставка для заказа ${orderId} была ${success ? "подтверждена" : "отменена"}.`);
            fetchOrders()
        } catch (error) {
            console.error(`Ошибка при обновлении статуса доставки для заказа ${orderId}`, error);
        }
    };

    const handleConfirmReturn = async (orderId: string) => {
        try {
            await OrderService.updateOrderStatusToReturned(orderId);
            console.log(`Возврат для заказа ${orderId} был подтвержден.`);
            fetchOrders()
        } catch (error) {
            console.error(`Ошибка при обновлении статуса возврата для заказа ${orderId}`, error);
        }
    };

    const renderOrderList = (orders: Order[], emptyMessage: string, maxHeight?: string) => (
        <Paper sx={{ padding: '16px', marginBottom: '20px', boxShadow: 'none', borderRadius: '0' }}>
            {orders.length > 0 ? (
                <List sx={{ maxHeight: (maxHeight || '200px'), overflowY: 'auto' }}>
                    {orders.map(order => (
                        <ListItem
                            key={order.id}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '16px',
                                marginBottom: '12px',
                                borderRadius: '20px',
                                backgroundColor: theme.palette.grey[200],
                                transition: 'background-color 0.2s ease-in-out',
                                '&:hover': {
                                    backgroundColor: theme.palette.grey[300]
                                }
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemText
                                    primary={
                                        <Link href={`#/${order.id}`} color="inherit" underline="hover" sx={{
                                            fontWeight: 800
                                        }}>
                                            {`Заказ №${order.id}`}
                                        </Link>
                                    }
                                    secondary={`Цена: ${order.price} руб. | Статус: ${OrderStatusText[order.currentStatus]}`}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {order.currentStatus === OrderStatus.VERIFYING && !order.isPassportInfoExists && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => navigate(`/admin/panel/passport`)}
                                        sx={{ marginRight: '8px' }}
                                    >
                                        Проверка паспорта
                                    </Button>
                                )}
                                {renderActionButton(order)}
                                <IconButton
                                    color="primary"
                                    onClick={() => handleClickOpen(order)}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body1">{emptyMessage}</Typography>
                </Box>
            )}
        </Paper>
    );

    const renderOrderDetails = (order: Order) => (
        <Dialog open={openDialog} onClose={handleClose} PaperProps={{ sx: { borderRadius: '15px' } }}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: theme.palette.primary.main }}>
                {`Детали заказа №${order.id}`}
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Товары</TableCell>
                            <TableCell>
                                {order.items.map((item, index) => (
                                    <Box key={index}>
                                        <Link
                                            href={`#/${order.id}/item/${index + 1}`}
                                            color="inherit"
                                            underline="hover"
                                            sx={{ display: 'block' }}
                                        >
                                            {`${item.productId} - ${item.itemPrice} руб.`}
                                        </Link>
                                    </Box>
                                ))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Общая цена</TableCell>
                            <TableCell>{order.price} руб.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Статус</TableCell>
                            <TableCell>{OrderStatusText[order.currentStatus]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>История статусов</TableCell>
                            <TableCell>
                                {order.statusHistory.map((history, index) => (
                                    <Typography key={index}>
                                        {`${OrderStatusText[history.status]} (изменено: ${dayjs(history.changedAt).format('DD.MM.YYYY HH:mm')})`}
                                    </Typography>
                                ))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Период аренды</TableCell>
                            <TableCell>
                                {`${dayjs(order.fromDate).format('DD.MM.YYYY HH:mm')} - ${dayjs(order.toDate).format('DD.MM.YYYY HH:mm')}`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Доп. комментарий</TableCell>
                            <TableCell>{order.description}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Контактные данные</TableCell>
                            <TableCell>
                                <Typography>эл. почта - {order.contactEmail}</Typography>
                                <Typography>телефон - {order.contactPhone}</Typography>
                                <Typography>ФИО - {order.secondName} {order.firstName} {order.patronymic}</Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Адрес доставки</TableCell>
                            <TableCell>
                                {order.deliveryAddress}
                                <br />
                                {order.dateOfDelivery ? dayjs(order.dateOfDelivery).format('DD.MM.YYYY HH:mm') : 'Время не указано'}
                                {(order.currentStatus === OrderStatus.PREPARE
                                    || order.currentStatus === OrderStatus.IN_RENT
                                    || order.currentStatus === OrderStatus.DELIVERY_REGISTERED) && (
                                    <Button variant="outlined" color="primary" onClick={() => handleAddressDialogOpen(false)} sx={{ marginLeft: '16px' }}>
                                        Изменить адрес/дату
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Адрес возврата</TableCell>
                            <TableCell>
                                {order.returnAddress || 'Не указан'}
                                <br />
                                {order.dateOfReturn ? dayjs(order.dateOfReturn).format('DD.MM.YYYY HH:mm') : 'Время не указано'}
                                {(order.currentStatus === OrderStatus.IN_RENT
                                    || order.currentStatus === OrderStatus.CANCELED_DURING_RENT
                                    || order.currentStatus === OrderStatus.RETURN_REGISTERED
                                    || order.currentStatus === OrderStatus.RETURN_REGISTERED_AFTER_CANCELLATION) && (
                                    <Button variant="outlined" color="primary" onClick={() => handleAddressDialogOpen(true)} sx={{ marginLeft: '16px' }}>
                                        Изменить адрес/дату
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );

    const renderActionButton = (order: Order) => {
        const currentDate = dayjs();
        const deliveryOrReturnDate = dayjs(order.dateOfDelivery || order.dateOfReturn);

        if (order.currentStatus === OrderStatus.DELIVERY_REGISTERED && currentDate.isSame(order.dateOfDelivery, 'day')) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleConfirmDelivery(order.id, true)}
                        sx={{ marginRight: '8px' }}
                    >
                        Подтвердить доставку
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleConfirmDelivery(order.id, false)}
                    >
                        Отменить доставку
                    </Button>
                </Box>
            );
        } else if ((order.currentStatus === OrderStatus.RETURN_REGISTERED || order.currentStatus === OrderStatus.RETURN_REGISTERED_AFTER_CANCELLATION) && currentDate.isSame(order.dateOfReturn, 'day')) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleConfirmReturn(order.id)}
                        sx={{ marginRight: '8px' }}
                    >
                        Подтвердить возврат
                    </Button>
                </Box>
            );
        }

        return null;
    };

    const renderAddressDialog = () => (
        <Dialog open={isAddressDialogOpen} onClose={handleAddressDialogClose} PaperProps={{ sx: { borderRadius: '15px' } }}>
            <DialogTitle>{isReturn ? "Изменить адрес и дату возврата" : "Изменить адрес и дату доставки"}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Новый адрес"
                    fullWidth
                    margin="normal"
                    onChange={(e) => setNewAddress(e.target.value)}
                    value={newAddress || (isReturn ? selectedOrder?.returnAddress || '' : selectedOrder?.deliveryAddress || '')}
                />
                <TextField
                    label="Новая дата"
                    fullWidth
                    margin="normal"
                    type="datetime-local"
                    onChange={(e) => setNewDate(e.target.value)}
                    value={newDate || dayjs(isReturn ? selectedOrder?.dateOfReturn : selectedOrder?.dateOfDelivery).format('YYYY-MM-DDTHH:mm')}
                />
                <Button variant="contained" color="primary" onClick={handleSaveChanges} sx={{ marginTop: '16px' }}>
                    Сохранить изменения
                </Button>
            </DialogContent>
        </Dialog>
    );

    return (
        <Container sx={{ minHeight: '100vh' }}>
            <Typography variant="h4" sx={{ fontWeight: 800, marginBottom: '15px', color: theme.palette.primary.main }}>
                Список заказов с необходимыми действиями
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: '5px' }}>
                Список заказов с оформленной доставкой/возвратом
            </Typography>

            {renderOrderList(urgentDeliveryOrReturnOrders, "Элементов нет.")}

            <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: '5px' }}>
                Список заказов с необходимым подтверждением паспорта
            </Typography>

            {renderOrderList(urgentOrders, "Элементов нет.")}

            <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: '5px' }}>
                Список заказов с необходимостью назначения даты доставки/возврата
            </Typography>
            {renderOrderList(deliveryOrReturnRequiredOrders, "Элементов нет.")}

            <Typography variant="h4" sx={{ fontWeight: 800, marginBottom: '15px', color: theme.palette.primary.main }}>
                Список всех заказов
            </Typography>

            <Box sx={{ mb: 3 }}>
                <TextField
                    label="Поиск по параметрам"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ minWidth: '400px', flexGrow: 1 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <Box sx={{ mb: '60px' }}>
                {renderOrderList(allOrders, "Элементов нет.", '500px')}
            </Box>

            {selectedOrder && renderOrderDetails(selectedOrder)}
            {renderAddressDialog()}
        </Container>
    );
};

export default OrderComponent;
